<template>
  <b-card>
    <g-table
      ref="vacationRequest-table"
      :items="items"
      :columns="tableColumns"
      :no-action="true"
      per-page="25"
      :total-rows="totalRows"
      :createButton="{ visiable: true, permission: 'addVacationRequest' }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push({ name: 'newVacationRequest' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            data-action-type="preview"
            v-b-tooltip.hover.top="$t('preview')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'editVacationRequest'"
            @click="edit(item)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mx-1 clickable"
              :hidden="true"
              :id="`invoice-row-${item.id}-prev-icon`"
            />
          </b-button>
          <b-button
            data-action-type="edit"
            v-b-tooltip.hover.top="$t('edit')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'editVacationRequest'"
            @click="edit(item)"
          >
            <feather-icon
              icon="EditIcon"
              :id="`invoice-row-${item.id}-preview-icon`"
            />
          </b-button>
          <b-button
            data-action-type="delete"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            v-permission="'deleteVacationRequest'"
            @click="remove(item)"
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            v-permission="'viewVacationRequest'"
            data-action-type="print"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`vacation-request-${item.id}-print-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      searchQuery: '',
      itemsArray: [],
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filterOn: [],
      filter: {
        orderClause: null,
        studentName: null,
        stageId: null,
        rowId: null,
        classroomId: null,
        registrationDateFrom: null,
        registrationDateTo: null,
        nationalityId: null,
      },
      isSortDirDesc: false,
      sortDirection: 'asc',
      employees: [],
      VacationTypes: [],
      adoptedFroms: [],
      items: [],
      selectedItem: {
        code: '',
        transactionDate: this.today,
        employeeId: 0,
        startDate: this.today,
        endDate: this.today,
        typeVacationId: 0,
        adoptedFromId: 0,
      },
      modal: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: 'employeeArabicName',
          label: this.$t('employee'),
          sortable: true,
        },
        {
          key: 'typeArabicName',
          label: this.$t('VacationType'),
          sortable: true,
        },
        {
          key: 'adoptedFromArabicName',
          label: this.$t('adoptedFrom'),
          sortable: true,
        },
        {
          key: 'startDate',
          label: this.$t('startVacationForm'),
          sortable: true,
        },
        {
          key: 'endDate',
          label: this.$t('endVacationAt'),
          sortable: true
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getItems();
    this.getEmployee();
    this.getVacationType();
  },
  methods: {
    validateDates() {
      this.selectedItem.transactionDate = this.selectedItem.transactionDate ?? this.today;
      this.selectedItem.startDate = this.selectedItem.startDate ?? this.today;
      this.selectedItem.endDate = this.selectedItem.endDate ?? this.today;
    },

    refreshItems() {
      this.$refs['vacationRequest-table'].refreshTable();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getItems() {
      this.get({ url: 'RequestVacation' }).then((data) => {
        data.forEach((item) => {
            item.startDate = this.getDate(item.startDate);
            item.endDate = this.getDate(item.endDate);
          });
          this.items = data;
      });
    },

    itemsProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      this.get({ url: `RequestVacation${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            item.transactionDate = this.getDate(item.transactionDate);
            item.startDate = this.getDate(item.startDate);
            item.endDate = this.getDate(item.endDate);
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'RequestVacation', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'editVacationRequest',
        params: { id: item.id },
      });
    },

    print(item) {
        const reportName = this.isRight ? 'VacationRequest-ar' : 'VacationRequest-en';
        const printedItem = {
          vacationRequestId: item.id,
        }
        this.printReport(reportName, printedItem);
    },
  },
};
</script>
<style></style>
